import * as yup from 'yup'
import { withFormik } from 'formik'
import { salesAPI } from '../../API'
import { displayToast } from '../../utils/Helpers'

const validationSchema = yup.object().shape({
  customer: yup.string().required('Selecione um cliente'),
  labor: yup.string().required('Mão de obra é obrigatória'),
  discountType: yup.string().required(),
  phone: yup.string().required('Infome um telefone'),
  carIndex: yup.string().required('Selecione um carro'),
  discount: yup.number()
    .positive('Valor deve ser positivo')
    .when('discountType', {
      is: '%',
      then: yup.number().max(99, 'Valor inválido')
    })
})

export const formikEnhancer = withFormik({
  isInitialValid: false,
  displayName: 'serviceForm',
  validateOnChange: true,
  validateOnBlur: true,
  validationSchema,
  handleSubmit: async (values, { props }) => {

    const result = values._id
      ? await salesAPI.update(values)
      : await salesAPI.insert(values)

    if (result.error) {
      displayToast(result.error)
      return
    }

    displayToast(result.message, 'success')
    props.history.push('/vendas')
  },
  mapPropsToValues: () => ({
    customer: '',
    phone: '',
    carIndex: '',
    mileage: '',
    mechanic: '',
    discountType: 'R$',
    services: [],
    discount: 0,
    bruteTotal: '',
    liquidTotal: '',
    labor: '',
    obs: '',
    newService: { _id: '', name: '', price: '', amount: 1 },
    status: ''
  })
})
