import React from 'react'
import { Button, Popup } from 'semantic-ui-react'

const RemoveOrEdit = ({ editItem, item, deleteItem, printItem, usePrint = false }) => (
  <div style={{ display: 'flex' }}>
    <Button
      icon='pencil'
      color='blue'
      inverted
      onClick={() => editItem(item)}
    />
    <Popup
      on='click'
      position='top center'
      closeOnPortalMouseLeave
      trigger={
        <Button icon='trash alternate outline' color='red' inverted />
      }
      content={
        <Button
          color='red'
          content='Confirmo excluir'
          onClick={() => deleteItem(item._id)}
        />
      }
    />
    {usePrint && item.situation === 'finalizada' && (
      <Button
        icon='print'
        color='purple'
        inverted
        onClick={() => printItem(item._id)}
      />
    )}
  </div>
)

export default RemoveOrEdit