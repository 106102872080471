import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs

const pdfGenerator = ({ sale, customerAditionalData }) => {
  const { customer, obs } = sale
  const { car: { index } } = customer
  const { cpf, name, rg, contact, address } = customerAditionalData
  const car = customerAditionalData.cars[index]
  const { street, number, neighborhood, city } = address
  const addressFormated = `${street}, ${number} - ${neighborhood} ${city}`

  const docDefinition = {

    content: [
      header(),
      {
        style: 'table',
        table: {
          widths: [320, 180],
          body: [
            [{ text: 'DADOS DO CLIENTE', alignment: 'center', colSpan: 2, bold: true }, ''],
            [
              customerData(name, cpf, rg, addressFormated),
              customerContact(contact)
            ],

            [{ text: 'DADOS DO VEÍCULO', alignment: 'center', colSpan: 2, bold: true }],
            [
              carData(car),
              dateHourMileage(sale)
            ],

            [{ text: 'SERVIÇOS REALIZADOS', alignment: 'center', colSpan: 2, bold: true }],
            ...servicesList(sale.services),

            totals(car)
          ]
        }
      },
      observation(obs),
      { style: 'assign', text: '____________________________________', fontSize: 15, bold: true },
      { text: [{ text: 'MECANICO RESPONSÁVEL:   ', bold: true }, { text: car.mechanic }] }
    ],
    styles: {
      table: { margin: [0, 5, 0, 15] },
      obs: { margin: [0, 5, 0, 30] },
      assign: { margin: [0, 10, 0, 0] }
    }
  }

  pdfMake.createPdf(docDefinition).open()
}

const header = () => ({
  text: [
    { text: 'OFICINA DO YURI\n', fontSize: 22, alignment: 'center' },
    { text: 'Rua do chá, 447, Jardim Pérola - SBO\n', fontSize: 16, alignment: 'center' },
    { text: '(19) 9 9188-9630 ', fontSize: 16, alignment: 'center' }
  ]
})


const dateHourMileage = ({ finishedAt, mileage }) => ({
  text: [
    { text: 'DATA:', bold: true }, { text: `${finishedAt.date}\n` },
    { text: 'HORA:', bold: true }, { text: `${finishedAt.time}\n` },
    { text: 'QUILOMETRAGEM:', bold: true }, { text: ` ${mileage} \n` }
  ]
})

const carData = ({ manufacturer, model, year, color }) => ({
  text: [
    { text: 'MARCA:', bold: true }, { text: ` ${manufacturer} \n` },
    { text: 'MODELO:', bold: true }, { text: ` ${model} \n` },
    { text: 'ANO:', bold: true }, { text: ` ${year} \n` },
    { text: 'COR:', bold: true }, { text: ` ${color} \n` }
  ]
})

const customerContact = ({ cellphone, cellphone2, telephone, email }) => ({
  text: [
    { text: 'CELULARES:\n', bold: true }, { text: `${cellphone} ${cellphone2 ? `\n${cellphone2}\n` : '\n'}` },
    { text: 'TELEFONE: ', bold: true }, { text: `${telephone}\n` },
    { text: 'EMAIL: ', bold: true }, { text: `${email}` }
  ]
})

const customerData = (name, cpf, rg, addressFormated) => ({
  text: [
    { text: 'NOME DO CLIENTE:', bold: true }, { text: ` ${name}\n` },
    { text: 'CPF:', bold: true }, { text: ` ${cpf}\n` },
    { text: 'RG:', bold: true }, { text: ` ${rg}\n` },
    { text: 'ENDEREÇO:\n', bold: true }, { text: `${addressFormated}` }
  ]
})

const totals = ({ bruteTotal, liquidTotal, labor, discount }) => (
  [{ text: 'VALORES', alignment: 'center', colSpan: 2, bold: true }],
  [{ text: 'TOTAL BRUTO', bold: true, alignment: 'right' }, { text: bruteTotal, alignment: 'left' }],
  [{ text: 'DESCONTO', bold: true, alignment: 'right' }, { text: discount, alignment: 'left' }],
  [{ text: 'TOTAL LÍQUIDO', bold: true, alignment: 'right' }, { text: liquidTotal, alignment: 'left' }],
  [{ text: 'MÃO DE OBRA', bold: true, alignment: 'right' }, { text: labor, alignment: 'left' }],
  [{ text: 'VALOR FINAL', bold: true, alignment: 'right' }, { text: '640,00', alignment: 'left' }]
)

const servicesList = services => services.map(({ name, amount, price }) => (
  [`${name}`, `${amount} x ${price} = ${amount * price}`]
))

const observation = obs => ({
  style: 'obs',
  text: [
    { text: 'OBSERVAÇÕES:   ', bold: true },
    { text: obs || 'não há garantia. La garantia soy jo' }
  ]
})


export default pdfGenerator