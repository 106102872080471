import React from 'react'
import { Button, Popup } from 'semantic-ui-react'

const SaveOrCancel = ({ onCancel, onSubmit, saveText = 'Salvar', cancelText = 'Cancelar', isUpdate, ...rest }) => (
  <Button.Group floated='right' style={{ bottom: 5, right: 5 }}>
    <Button
      type='submit'
      content={isUpdate ? 'Alterar' : saveText}
      positive
      onClick={onSubmit}
      {...rest}
    />
    <Button.Or text='ou' />
    <Popup
      on='click'
      position='top right'
      content={
        <Button
          color='red'
          type='button'
          inverted
          content={`Confirmo ${cancelText}`}
          onClick={onCancel}
        />
      }
      trigger={
        <Button
          type='button'
          content={cancelText}
          negative
          disabled={rest.loading}
        />
      }
    />
  </Button.Group>
)

export default SaveOrCancel