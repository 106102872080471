import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify'
import Login from './screens/Login/Login'
import Sale from './screens/Sale/Sale'
import Services from './screens/Services/Services'
import Main from './Main'
import ServiceForm from './screens/Services/ServiceForm'
import { Container } from 'semantic-ui-react'
import Customer from './screens/Customer/Customer'
import CustomerForm from './screens/Customer/CustomerForm'
import SaleForm from './screens/Sale/SaleForm'
import Settings from './screens/Settings/Settings'
import Menu from './screens/Menu/Menu'

const App = () => (
  <Container fluid>
    <Router>
      <Switch>
        <PrivateRoute path='/' component={Menu} exact />
        <PrivateRoute path='/clientes' component={Customer} exact />
        <PrivateRoute path='/vendas' component={Sale} exact />
        <PrivateRoute path='/servicos' component={Services} exact />
        <PrivateRoute path='/configuracoes' component={Settings} />
        <PrivateRoute path='/clientes/(novo|edicao)/:id?' component={CustomerForm} />
        <PrivateRoute path='/vendas/(nova|edicao)/:id?' component={SaleForm} />
        <PrivateRoute path='/servicos/(novo|edicao)/:id?' component={ServiceForm} />
        <Route path='/login' component={Login} />
      </Switch>
    </Router>
    <ToastContainer
      autoClose={3000}
      transition={Flip}
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnVisibilityChange
      pauseOnHover
    />
  </Container>
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      !sessionStorage.token
        ? <Redirect to='/login' />
        : (
          <Main>
            <Component {...props} />
          </Main>
        )
    )}
  />
)

export default App
