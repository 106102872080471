import React, { Component } from 'react'
import { Card, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'

class Menu extends Component {
	state = {
	  menu: [
	    { title: 'Clientes', linkNew: '/clientes/novo', linkList: '/clientes', icon: 'user' },
	    { title: 'Serviços', linkNew: '/servicos/novo', linkList: '/servicos', icon: 'wrench' },
	    { title: 'Vendas', linkNew: '/vendas/nova', linkList: '/vendas', icon: 'dollar' }
	  ]
	}

	render() {
	  const { menu } = this.state
	  return (
	    <div className={css(styles.cardGroup)}>
	      {menu.map(item => (
	        <Card
	          key={item.linkList}
	          raised
	          className={css(styles.card)}
	        >
	          <Card.Content textAlign='center'>
	            <Card.Header>
	              <h1>{item.title}</h1>
	            </Card.Header>
	            <Icon
	              name={item.icon}
	              size='massive'
	              className={css(styles.icon)}
	            />
	          </Card.Content>
	          <Card.Content extra textAlign='center'>
	            <ButtonLink
	              content='Novo'
	              to={item.linkNew}
	              color='green'
	              icon='plus'
	            />
	            <ButtonLink
	              content='Listar'
	              to={item.linkList}
	              color='blue'
	              icon='list'
	            />
	          </Card.Content>
	        </Card>
	      ))}
	    </div>
	  )
	}
}

const ButtonLink = ({ children, ...rest }) => (
  <Button
    labelPosition='left'
    as={Link}
    {...rest}
  />
)

const styles = StyleSheet.create({
  cardGroup: {
    display: 'flex',
    margin: 0,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '30em'
  },
  card: {
    margin: 0,
    height: '20em'
  },
  icon: {
    marginTop: 40
  }
})

export default Menu