import React from 'react'
import { Table, Input, Button, Popup } from 'semantic-ui-react'
import { genID } from '../../utils/Helpers'
import { Label } from '../../components/Inputs'

const MechanicTable = ({
  mechanic, mechanicsList, onChange, handleAdd,
  handleActiveMechanic, deleteItem, onPressEnter
}) => {
  const nameAlreadyTaken = () => mechanicsList.find(x => x.name === mechanic.name)
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell colSpan={2}>
          <Label label='Cadastrar novo mecânico' />
          <Input
            onChange={onChange}
            onKeyDown={onPressEnter}
            value={mechanic.name}
            action={{
              color: 'teal',
              content: 'Cadastrar',
              onClick: handleAdd,
              disabled: !mechanic.name || nameAlreadyTaken()
            }}
            fluid
          />
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {mechanicsList.filter(x => !x.wasDeleted).map((mechanic, index) => (
          <Table.Row key={genID()}>
            <Table.Cell content={mechanic.name} />
            <Table.Cell textAlign='right'>
              <ToggleActive
                active={mechanic.active}
                inverted
                onClick={() => handleActiveMechanic(index)}
              />
              <Popup
                on='click'
                position='right center'
                closeOnPortalMouseLeave
                trigger={
                  <Button
                    icon='trash alternate outline'
                    disabled={mechanic.inSale}
                    color='red'
                    inverted
                  />
                }
                content={
                  <Button
                    color='red'
                    content='Confirmo'
                    onClick={() => deleteItem(mechanic)}
                  />
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const ToggleActive = ({ active, ...rest }) =>
  active
    ? <Button color='blue' icon='eye' {...rest} />
    : <Button color='orange' icon='eye slash' {...rest} />

export default MechanicTable
