import React, { memo } from 'react'
import { Statistic, Grid, Segment } from 'semantic-ui-react'
import { Field } from 'formik'
import { FInput, FDropdown } from '../../components/Inputs'
import { ErrorMessage } from '../../components/ErrorMessage'
import { displayPretty } from '../../utils/Helpers'

const Total = ({ values, disabled, setFieldTouched, setFieldValue }) => {
  let { services, discountType, labor } = values
  let { discount, liquidTotal } = getParsedValues(values)
  let bruteTotal = 0, discountValue = discount
  if (services.length) {
    bruteTotal = services
      .map(x => x.price * x.amount)
      .reduce((x, y) => x + y)
    if (discount) {
      if (discountType === 'R$') {
        liquidTotal = bruteTotal - discount + parseFloat(labor)
      } else {
        discountValue = (bruteTotal * discount) / 100
        liquidTotal = bruteTotal - discountValue + parseFloat(labor)
      }
    } else {
      liquidTotal = bruteTotal + parseFloat(labor)
    }
  } else {
    liquidTotal = labor
  }
  values.bruteTotal = bruteTotal
  values.liquidTotal = liquidTotal
  values.labor = labor

  return (
    <Segment raised>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Field
              label='Desconto'
              name='discount'
              type='number'
              disabled={disabled}
              maxLength={3}
              component={FInput}
              min={0}
            />
            <ErrorMessage name='discount' />
          </Grid.Column>
          <Grid.Column width={4}>
            <Field
              name='discountType'
              label='Tipo'
              component={FDropdown}
              disabled={disabled}
              options={options}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Field
              name='labor'
              component={FInput}
              type='number'
              label='Mão de obra'
              disabled={disabled}
              maxLength={4}
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Statistic
              label='Peças'
              value={`R$ ${displayPretty(bruteTotal)}`}
              color='blue'
            />
            <br />
            <Statistic
              label={labelDiscount(discountType, discount)}
              value={`R$ ${displayPretty(discountValue, discountType)}`}
              color='red'
            />
            <br />
            <Statistic
              label='Mão de obra'
              value={`R$ ${displayPretty(isNaN(labor) || !labor ? 0 : labor)}`}
              color='green'
            />
            <br />
            <Statistic
              label='Total'
              value={`R$ ${displayPretty(isNaN(liquidTotal) || !liquidTotal ? 0 : liquidTotal)}`}
              color='green'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

const options = [
  { key: '%', text: '%', value: '%' },
  { key: 'R$', text: 'R$', value: 'R$' }
]

const getParsedValues = ({ discount, liquidTotal, labor }) => {
  discount = parseFloat(discount || 0)
  liquidTotal = parseFloat(liquidTotal || 0)
  labor = parseFloat(labor || 0)
  return { discount, liquidTotal, labor }
}

const labelDiscount = (discountType, discount) =>
  `Desconto (${discountType === '%' ? discount : ''} ${discountType})`

export default memo(Total)
