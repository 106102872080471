import React, { PureComponent } from 'react'
import { withFormik, Form, Field, FieldArray } from 'formik'
import { Grid, Button, Divider } from 'semantic-ui-react'
import SaveOrCancel from '../../components/SaveOrCancel'
import { FInput, MaskedInput } from '../../components/Inputs'
import { ErrorMessage } from '../../components/ErrorMessage'
import * as yup from 'yup'
import { getOnlyNumbersOf, displayToast } from '../../utils/Helpers'
import { customersAPI, getAddressByCep } from '../../API'
const { Column } = Grid

const car = { manufacturer: '', model: '', year: '', color: '' }

const Row = ({ children }) => (
  <Grid.Row style={{ height: 85, padding: '10px 0 0 0' }}>
    {children}
  </Grid.Row>
)

const schema = yup.object().shape({
  name: yup.string()
    .required('Nome é obrigatório')
    .min(5, 'Infome pelo menos 5 letras'),
  cpf: yup.string()
    .required('CPF é obrigatório')
    .trim()
    .min(14, 'Preencha o CPF completo'),
  contact: yup.object({
    cellphone: yup.string()
      .min(13, 'Número inválido').trim()
      .required('Celular é obrigatório')
  }),
  cars: yup.array().of(
    yup.object({
      model: yup.string()
        .required('Modelo do carro é obrigatório'),
      year: yup.number()
        .nullable()
        .positive('Deve ser positivo')
        .moreThan(1900, 'Ano inválido')
        .notOneOf(['', NaN], 'O ano é obrigatório')
    })
  )
})

const formikEnhancer = withFormik({
  displayName: 'customer',
  validateOnBlur: true,
  validationSchema: schema,
  handleSubmit: async (values, { props }) => {
    values.cars = values.cars.map((item, index) => ({ ...item, index }))
    const result = values._id
      ? await customersAPI.update(values)
      : await customersAPI.insert(values)

    if (result.error) {
      displayToast(result.error)
      return
    }
    displayToast(result.message, 'success')
    props.history.push('/clientes')
  },
  mapPropsToValues: () => ({
    name: '', cpf: '', rg: '',
    contact: { cellphone: '', cellphone2: '', telephone: '', email: '' },
    address: { cep: '', street: '', number: '', neighborhood: '', city: '' },
    cars: [car]
  })
})

class Customer extends PureComponent {

	componentDidMount = () => this.checkIsUpdate()

	checkIsUpdate = async () => {
	  const { match, setValues } = this.props
	  const { id } = match.params
	  if (id) {
	    const values = await customersAPI.get(id)
	    setValues(values)
	  }
	}

	closeForm = () => {
	  const { resetForm, history } = this.props
	  history.push('/clientes')
	  resetForm()
	}

	onBlurCEP = async () => {
	  const { setValues, values, setFieldError, setFieldTouched } = this.props
	  if (getOnlyNumbersOf(values.address.cep).length < 8) return
	  const result = await getAddressByCep(values.address.cep)
	  if (result.erro) {
	    setFieldError('address.cep', 'Insira um CEP válido!')
	    setFieldTouched('address.cep', true, false)
	  } else {
	    const { logradouro: street, bairro: neighborhood, localidade: city } = result
	    setValues({ ...values, address: { ...values.address, street, neighborhood, city } })
	    document.getElementsByName('address.number')[0].focus()
	  }
	}

	render() {
	  const { isSubmiting, isValid, values } = this.props
	  return (
	    <Form>
	      <Divider content='Dados pessoais' horizontal />
	      <Grid>
	        <Row>
	          <Column width={7}>
	            <Field
	              name='name'
	              label='Nome'
	              component={FInput}
	              required
	            />
	          </Column>
	          <Column width={3}>
	            <Field
	              name='cpf'
	              label='CPF'
	              mask='111.111.111-11'
	              component={MaskedInput}
	              required
	            />
	          </Column>
	          <Column width={3}>
	            <Field
	              component={MaskedInput}
	              name='rg'
	              label='RG'
	              mask='11.111.111-*'
	            />
	          </Column>
	        </Row>
	        <Row>
	          <Column width={3}>
	            <Field
	              component={MaskedInput}
	              name='contact.cellphone'
	              label='Celular'
	              mask='(11)11111-1111'
	              required
	            />
	          </Column>
	          <Column width={3}>
	            <Field
	              component={MaskedInput}
	              name='contact.cellphone2'
	              label='Celular recado'
	              mask='(11)11111-1111'
	            />
	          </Column>
	          <Column width={3}>
	            <Field
	              name='contact.telephone'
	              label='Telefone fixo'
	              mask='(11)1111-1111'
	              component={MaskedInput}
	            />
	          </Column>
	          <Column width={4}>
	            <Field
	              name='contact.email'
	              label='Email'
	              type='email'
	              component={FInput}
	            />
	          </Column>
	        </Row>
	        <Row>
	          <Column width={2}>
	            <Field
	              name='address.cep'
	              label='CEP'
	              mask='11111-111'
	              onBlur={this.onBlurCEP}
	              component={MaskedInput}
	            />
	            <ErrorMessage name='address.cep' />
	          </Column>
	          <Column width={4}>
	            <Field
	              name='address.street'
	              label='Endereço'
	              component={FInput}
	            />
	          </Column>
	          <Column width={2}>
	            <Field
	              name='address.number'
	              label='Número'
	              type='number'
	              maxLength={4}
	              min={0}
	              component={FInput}
	            />
	          </Column>
	          <Column width={4}>
	            <Field
	              name='address.neighborhood'
	              label='Bairro'
	              component={FInput}
	            />
	          </Column>
	          <Column width={4}>
	            <Field
	              name='address.city'
	              label='Cidade'
	              component={FInput}
	            />
	          </Column>
	        </Row>
					<>
						<Divider content='Carros' horizontal />
						<FieldArray name='cars'>
						  {arrHelper => (
								<>
									{values.cars.map((_, index) => (
									  <Row key={index}>
									    <Column width={3}>
									      <Field
									        label='Marca'
									        name={`cars[${index}].manufacturer`}
									        component={FInput}
									      />
									    </Column>
									    <Column width={3}>
									      <Field
									        label='Modelo'
									        name={`cars[${index}].model`}
									        component={FInput}
									        required
									      />
									    </Column>
									    <Column width={3}>
									      <Field
									        label='Cor'
									        name={`cars[${index}].color`}
									        component={FInput}
									      />
									    </Column>
									    <Column width={2}>
									      <Field
									        label='Ano'
									        type='number'
									        name={`cars[${index}].year`}
									        component={FInput}
									        maxLength={4}
									        min={0}
									        required={index === 0}
									      />
									    </Column>
									    <Column width={1} verticalAlign='middle'>
									      <RemoveCarButton
									        onClick={() => arrHelper.remove(index)}
									        index={index}
									        arrHelper={arrHelper}
									      />
									    </Column>
									  </Row>
									))}
									<Row>
									  <Column width={3}>
									    <AddNewCarButton onClick={() => arrHelper.push(car)} />
									  </Column>
									</Row>
								</>
						  )}
						</FieldArray>
					</>
					<Row>
					  <Column width={16}>
					    <SaveOrCancel
					      isUpdate={values._id}
					      disabled={isSubmiting || !isValid}
					      loading={isSubmiting}
					      onCancel={this.closeForm}
					    />
					  </Column>
					</Row>
	      </Grid>
	    </Form>
	  )
	}
}

const RemoveCarButton = ({ arrHelper, index, ...rest }) => (
  <Button
    icon='trash alternate outline'
    type='button'
    color='red'
    inverted
    style={{ height: 38 }}
    disabled={index === 0 && arrHelper.form.values.cars.length === 1}
    {...rest}
  />
)

const AddNewCarButton = props => (
  <Button
    type='button'
    color='blue'
    icon='plus'
    content='Adicionar novo carro'
    inverted
    {...props}
  />
)

export default formikEnhancer(Customer)
