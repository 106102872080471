import React from 'react'
import { Input, Dropdown, Icon } from 'semantic-ui-react'
import Masked from 'react-maskedinput'
import './Input.css'
import { ErrorMessage } from './ErrorMessage'

export const FInput = ({ label, field, required, maxLength, form, ...rest }) => (
	<>
		<Label label={label} required={required} />
		<Input
		  {...field}
		  {...rest}
		  fluid
		  onBlur={() => form.setFieldTouched(field.name)}
		  onChange={(_, { name, value }) => {
		    if (maxLength) {
		      if (value.length <= maxLength) form.setFieldValue(name, value, true)
		    } else {
		      form.setFieldValue(name, value, true)
		    }
		  }}
		/>
		{required && <ErrorMessage name={field.name} />}
	</>
)

export const FDropdown = ({ field, setFieldValue, setFieldTouched, required, label, ...props }) => (
	<>
		<Label label={label} required={required} />
		<Dropdown
		  {...field}
		  {...props}
		  onChange={(_, { name, value }) => setFieldValue(name, value, true)}
		  onBlur={(_, { name }) => setFieldTouched(name, true, true)}
		  noResultsMessage='Nenhum resultado encontrado'
		  fluid
		  selection
		  search
		  deburr
		/>
		{required && <ErrorMessage name={field.name} />}
	</>
)

export const MaskedInput = ({ label, mask, field, required, error, ...rest }) => (
	<>
		<Label label={label} required={required} />
		<div className='ui labeled input fields'>
		  <Masked placeholderChar=' ' mask={mask} {...field} {...rest} />
		</div>
		{required && <ErrorMessage name={field.name} />}
	</>
)

export const Label = ({ label, required }) => (
  <label>
    <strong>
      {label}
      {required && (
        <Icon
          color='red'
          size='small'
          name='asterisk'
          style={{ position: 'absolute' }}
        />
      )}
    </strong>
  </label>
)
