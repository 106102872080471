import React from 'react'
import { Table } from 'semantic-ui-react'
import RemoveOrEdit from '../../components/RemoveOrEdit'
import TableMessage from '../../components/TableMessage'

const ServiceList = ({ services, deleteItem, editItem, tableMessage }) => (
  <Table striped selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content='Nome' />
        <Table.HeaderCell content='Preço' />
        <Table.HeaderCell content='Ações' textAlign='center' collapsing />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {!services.length
        ? <TableMessage tableMessage={tableMessage} />
        : (
          services.map(item => (
            <Table.Row key={item._id}>
              <Table.Cell content={item.name} />
              <Table.Cell content={item.price ? item.price.toFixed(2).replace('.', ',') : ' - '} />
              <Table.Cell>
                <RemoveOrEdit
                  editItem={editItem}
                  item={item}
                  deleteItem={deleteItem}
                />
              </Table.Cell>
            </Table.Row>
          ))
        )
      }
    </Table.Body>
  </Table>
)

export default ServiceList