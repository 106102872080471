import React, { PureComponent } from 'react'
import { Form, Field } from 'formik'
import { Grid, Button, Popup, TextArea } from 'semantic-ui-react'
import { FDropdown, FInput, MaskedInput, Label } from '../../components/Inputs'
import TableServicesInSale from './TableServicesInSale'
import Total from './Total'
import { customersAPI, servicesAPI, salesAPI, settingsAPI } from '../../API'
import { formikEnhancer } from './formikSale'
import Loading from '../../components/Loading'

class SaleForm extends PureComponent {
	state = {
	  isLoading: true,
	  customersOptions: [],
	  servicesOptions: [],
	  mechanicsOptions: []
	}

	componentDidMount = () => {
	  this.getDropdownOptions()
	}

	getDropdownOptions = async () => {
	  const [
	    customers,
	    services,
	    mechanics
	  ] = await Promise.all([
	    customersAPI.getAll(),
	    servicesAPI.getAll(),
	    settingsAPI.getMechanics()
	  ])

	  const { id } = this.props.match.params

	  const customersOptions = customers.map(({ contact, name, cars, ...x }) => {
	    cars = cars.map(y => ({ text: `${y.model} ${y.color} ${y.year}`, value: y.index }))
	    return { value: x._id, text: `${name} - ${x.cpf}`, name, contact, cars }
	  })

	  const servicesOptions = services.map(s =>
	    ({ price: s.price, value: s._id, text: s.name }))

	  const mechanicsOptions = mechanics
	    .filter(m => m.active)
	    .map(m => ({ value: m.name, text: m.name }))

	  const keepLoading = Boolean(id)

	  this.setState({
	    isLoading: keepLoading,
	    customersOptions,
	    servicesOptions,
	    mechanicsOptions
	  })

	  if (id) this.fillFormValues(id)
	}


	fillFormValues = async id => {
	  const form = await salesAPI.get(id)
	  const { setValues, values } = this.props
	  form.customer = form.customer._id
	  form.discount = form.discount || ''
	  setValues({ ...values, ...form })
	  this.setState({ isLoading: false })
	}


	handleCustomer = (_, _id) => {
	  const { contact, cars } = this.state.customersOptions.find(x => x.value === _id)
	  const { setValues, values } = this.props
	  const phone = contact.cellphone || contact.telephone
	  const carIndex = cars[0].value
	  setValues({
	    ...values,
	    carIndex,
	    phone,
	    customer: _id
	  })
	  getByName('mileage').focus()
	}

	handleSubmit = isFinished => () => {
	  const { setValues, values, submitForm } = this.props
	  setValues({
	    ...values,
	    situation: isFinished ? 'finalizada' : 'aberta'
	  })
	  setTimeout(() => submitForm(), 100)
	}

	addServiceToTable = () => {
	  const { setValues, initialValues, values } = this.props
	  const { newService, services } = values
	  setValues({
	    ...values,
	    services: [...services, newService],
	    newService: initialValues.newService
	  })
	}

	removeTableService = index => {
	  const { values, setValues } = this.props
	  values.services.splice(index, 1)
	  setValues({ ...values })
	}

	closeForm = () => this.props.history.push('/vendas')

	getPrices = (e, _id) => {
	  const { price, text: name } = this.state.servicesOptions.find(x => x.value === _id)
	  this.props.setFieldValue('newService', { _id, name, price, amount: 1 })
	  getByName('newService.price').focus()
	}

	onPressEnter = e => e.keyCode === 13 && this.addServiceToTable()

	render() {
	  const { customersOptions, mechanicsOptions, servicesOptions, isLoading } = this.state
	  const { setFieldTouched, setFieldValue, isSubmitting, values, isValid } = this.props
	  const isReadOnly = values.situation === 'finalizada'
	  const cars = carsList(values, customersOptions)

	  return (
	    <Form>
	      <Loading active={isLoading} />
	      <Grid>
	        <Grid.Row width={4}>
	          <Grid.Column width={4}>
	            <Field
	              name='customer'
	              label='Cliente'
	              component={FDropdown}
	              options={customersOptions}
	              setFieldValue={this.handleCustomer}
	              setFieldTouched={setFieldTouched}
	              disabled={isReadOnly}
	              required
	            />
	          </Grid.Column>
	          <Grid.Column width={3}>
	            <Field
	              name='phone'
	              label='Telefone'
	              mask='(11)11111-1111'
	              component={MaskedInput}
	              disabled={isReadOnly}
	              required
	            />
	          </Grid.Column>
	          <Grid.Column width={3}>
	            <Field
	              name='carIndex'
	              label='Veículo'
	              component={FDropdown}
	              options={cars}
	              setFieldValue={setFieldValue}
	              setFieldTouched={setFieldTouched}
	              disabled={isReadOnly}
	              required
	            />
	          </Grid.Column>
	          <Grid.Column width={3}>
	            <Field
	              name='mileage'
	              label='Quilometragem'
	              component={FInput}
	              type='number'
	              maxLength={6}
	              disabled={isReadOnly}
	              min={0}
	              fluid
	            />
	          </Grid.Column>
	          <Grid.Column width={3}>
	            <Field
	              name='mechanic'
	              label='Mecânico Responsável'
	              component={FDropdown}
	              options={mechanicsOptions}
	              setFieldValue={setFieldValue}
	              setFieldTouched={setFieldTouched}
	              disabled={isReadOnly}
	            />
	          </Grid.Column>
	        </Grid.Row>
	        <Grid.Row>
	          <Grid.Column width={10}>
	            <TableServicesInSale
	              setFieldTouched={setFieldTouched}
	              setFieldValue={setFieldValue}
	              servicesOptions={servicesOptions}
	              services={values.services}
	              newService={values.newService}
	              getPrices={this.getPrices}
	              addServiceToTable={this.addServiceToTable}
	              onPressEnter={this.onPressEnter}
	              remove={this.removeTableService}
	              disabled={isReadOnly}
	            />
	            <div className='ui form'>
	              <Label label='Observações' />
	              <TextArea
	                name='obs'
	                value={values.obs}
	                onChange={(_, { value }) => setFieldValue('obs', value, true)}
	                autoHeight
	                rows={15}
	                readOnly={isReadOnly}
	                style={{ color: isReadOnly ? '#a4a4a4' : 'inherit' }}
	              />
	            </div>
	          </Grid.Column>
	          <Grid.Column width={6}>
	            <Total
	              values={values}
	              setFieldTouched={setFieldTouched}
	              disabled={isReadOnly}
	              setFieldValue={setFieldValue}
	            />
	          </Grid.Column>
	        </Grid.Row>
	        <Grid.Row>
	          <Grid.Column floated='right' width={6}>
	            <Button.Group floated='right' style={{ bottom: 5 }}>
	              <Button
	                content='Registrar'
	                icon='pencil'
	                onClick={this.handleSubmit(false)}
	                disabled={isSubmitting || isReadOnly || !isValid}
	                loading={isSubmitting}
	                type='button'
	                primary
	              />
	              <Button.Or text='ou' />
	              <ButtonFinishSale
	                onClick={this.handleSubmit(true)}
	                isReadOnly={isReadOnly || !isValid}
	                isSubmitting={isSubmitting}
	              />
	              <Button.Or text='ou' />
	              {!isReadOnly ? (
	                <ButtonCancelSale
	                  isSubmitting={isSubmitting}
	                  onClick={this.closeForm}
	                />
	              ) : (
	                <Button
	                  content='Sair'
	                  color='red'
	                  icon='sign out'
	                  type='button'
	                  onClick={this.closeForm}
	                />
	              )
	              }
	            </Button.Group>
	          </Grid.Column>
	        </Grid.Row>
	      </Grid>
	    </Form>
	  )
	}
}

const carsList = ({ customer }, customersOptions) => {
  const result = customersOptions.find(x => x.value === customer)
  return result ? result.cars : []
}

export default formikEnhancer(SaleForm)

const ButtonCancelSale = ({ isSubmitting, onClick }) => (
  <Popup
    on='click'
    position='top right'
    content={
      <Button
        content='Confirmo cancelar'
        color='red'
        type='button'
        inverted
        onClick={onClick}
      />
    }
    trigger={
      <Button
        type='button'
        content='Cancelar'
        icon='cancel'
        color='red'
        disabled={isSubmitting}
      />
    }
  />
)

const ButtonFinishSale = ({ isSubmitting, isReadOnly, onClick }) => (
  <Popup
    on='click'
    position='top right'
    content={
      <Button
        content='Confirmo a finalização'
        color='green'
        type='button'
        inverted
        onClick={onClick}
      />
    }
    trigger={
      <Button
        content='Finalizar'
        icon='check'
        color='green'
        disabled={isSubmitting || isReadOnly}
        loading={isSubmitting}
        type='button'
      />
    }
  />
)

const getByName = name => document.getElementsByName(name)[0]

