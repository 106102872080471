import React, { memo } from 'react'
import { Input, Button, Select } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite'
import Masked from 'react-maskedinput'

import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 28rem;
`

const InputSearch = ({
  options = defaultOptions,
  searchText,
  handleChange,
  triggerChange,
  search,
  color,
  placeholder,
  optionSelected,
  searchSelected
}) => (
  <Container>
    <Select
      compact
      options={options}
      defaultValue='name'
      onChange={(_, e) => optionSelected && optionSelected(e.value)}
    />
    {searchSelected === 'date' ? (
      <MaskedDate
        handleChange={handleChange}
        triggerChange={triggerChange}
        searchText={searchText}
      />
    ) : (
      <Input
        onChange={handleChange}
        onKeyDown={triggerChange}
        value={searchText}
        placeholder={placeholder}
        className={css(styles.input)}
        autoFocus
      />
    )
    }
    <Button
      type='button'
      onClick={search}
      content='Procurar'
      color={color}
    />
  </Container>
)

const MaskedDate = ({ handleChange, triggerChange, searchText }) => (
  <div className={`ui labeled input fields ${css(styles.input)} `}>
    <Masked
      placeholderChar=' '
      mask='11/11/1111'
      onChange={handleChange}
      onKeyDownCapture={triggerChange}
      value={searchText}
      autoFocus
    />
  </div>
)

const styles = StyleSheet.create({
  input: {
    width: 200
  }
})

const defaultOptions = [
  { key: 'nome', text: 'Nome', value: 'name' }
]

export default memo(InputSearch)
