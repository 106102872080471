import React, { PureComponent } from 'react'
import { Grid } from 'semantic-ui-react'
import MechanicTable from './MechanicTables'
import SaveOrCancel from '../../components/SaveOrCancel'
import { settingsAPI } from '../../API'
import { displayToast } from '../../utils/Helpers'

const enter = 13

class Settings extends PureComponent {
	state = { mechanicsList: [], mechanicsRemoveList: [], mechanic: { name: '', active: true } }

	componentDidMount = async () => {
	  const mechanicsList = await settingsAPI.getMechanics()
	  if (mechanicsList.length) {
	    this.setState({ mechanicsList })
	    return
	  }
	}

	handleChange = ({ target }) => {
	  this.setState({ mechanic: { name: target.value, active: true } })
	}

	onPressEnter = ({ keyCode }) => {
	  if (keyCode === enter) this.handleAddMechanic()
	}

	handleAddMechanic = () => {
	  const { mechanicsList, mechanic } = this.state
	  this.setState({
	    mechanicsList: [...mechanicsList, mechanic],
	    mechanic: { name: '', active: true }
	  })

	}

	handleActiveMechanic = _index => {
	  const mechanicsList = this.state.mechanicsList.map((mechanic, index) =>
	    index !== _index ? mechanic : { ...mechanic, active: !mechanic.active }
	  )

	  this.setState({ mechanicsList })
	}

	handleDeleteMechanic = async ({ name, _id = '' }) => {
	  const mechanicsList = this.state.mechanicsList.map(mechanic =>
	    mechanic.name !== name ? mechanic : { ...mechanic, wasDeleted: true }
	  )

	  this.setState({ mechanicsList })
	}


	handleCancel = () => this.props.history.goBack()


	handleSubmit = async () => {
	  const { message } = await settingsAPI.mechanic(this.state)
	  displayToast(message, 'success')
	}

	render() {
	  const { mechanic, mechanicsList } = this.state
	  return (
	    <Grid>
	      <Grid.Row centered>
	        <Grid.Column width={8}>
	          <MechanicTable
	            handleAdd={this.handleAddMechanic}
	            onChange={this.handleChange}
	            mechanic={mechanic}
	            mechanicsList={mechanicsList}
	            deleteItem={this.handleDeleteMechanic}
	            handleActiveMechanic={this.handleActiveMechanic}
	            onPressEnter={this.onPressEnter}
	          />
	        </Grid.Column>
	      </Grid.Row>
	      <Grid.Row>
	        <Grid.Column width={16}>
	          <SaveOrCancel
	            onCancel={this.handleCancel}
	            onSubmit={this.handleSubmit}
	          />
	        </Grid.Column>
	      </Grid.Row>
	    </Grid>
	  )
	}
}

export default Settings