import React, { Component } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import Table from './CustomerTable'
import { displayToast } from '../../utils/Helpers'
import { customersAPI } from '../../API'
import Loading from '../../components/Loading'
import InputSearch from '../../components/InputSearch'
import { tableMessage, noItemFound } from '../../utils/constants'

class Customers extends Component {
	state = {
	  customers: [],
	  isLoading: false,
	  tableMessage,
	  search: '',
	  searchText: ''
	}

	handleChange = (_, { value }) => {
	  this.setState({ searchText: value })
	}

	triggerChange = ({ keyCode, target }) => {
	  if (keyCode === 13) this.search(target.value)
	}

	search = async value => {
	  const { searchText, tableMessage } = this.state
	  value = value || searchText
	  this.setState({ isLoading: true })
	  const customers = await customersAPI.getList(searchText)
	  this.setState({
	    customers,
	    tableMessage: customers.length ? tableMessage : noItemFound,
	    isLoading: false
	  })
	}

	deleteItem = async _id => {
	  const { message } = await customersAPI.remove(_id)
	  this.setState(prev => ({
	    customers: prev.customers.filter(x => x._id !== _id)
	  }))
	  displayToast(message, 'success')
	}

	editItem = ({ _id }) => this.redirect(`/clientes/edicao/${_id}`)

	newCustomer = () => this.redirect('/clientes/novo')

	redirect = path => this.props.history.push(path)

	render() {
	  const { customers, isLoading, searchText, tableMessage } = this.state
	  return (
			<>
				<Loading active={isLoading} />
				<Grid>
				  <Grid.Row>
				    <Grid.Column width={3}>
				      <Button
				        content='Novo Cliente'
				        icon='plus'
				        onClick={this.newCustomer}
				        labelPosition='left'
				        color='blue'
				      />
				    </Grid.Column>
				    <Grid.Column width={9} textAlign='right'>
				      <InputSearch
				        searchText={searchText}
				        handleChange={this.handleChange}
				        triggerChange={this.triggerChange}
				        search={this.search}
				        placeholder='Digite o nome do cliente ...'
				        color='blue'
				      />
				    </Grid.Column>
				  </Grid.Row>
				  <Grid.Row>
				    <Grid.Column>
				      <Table
				        customers={customers}
				        deleteItem={this.deleteItem}
				        editItem={this.editItem}
				        tableMessage={tableMessage}
				      />
				    </Grid.Column>
				  </Grid.Row>
				</Grid>
			</>
	  )
	}
}
export default Customers