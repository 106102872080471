import React from 'react'
import { Table, Button } from 'semantic-ui-react'
import { Field } from 'formik'
import { FDropdown, FInput } from '../../components/Inputs'
import { displayPretty } from '../../utils/Helpers'
import shortid from 'shortid'

const TableServices = ({
  servicesOptions, services = [], getPrices,
  addServiceToTable, onPressEnter, newService, remove, disabled, ...rest
}) => {
  const isNewServiceValid = newService._id && newService.amount && newService.price
  return (
    <Table striped singleLine selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={10}>
            <Field
              {...rest}
              name='newService._id'
              label='Serviços / Peças'
              component={FDropdown}
              options={servicesOptions}
              setFieldValue={getPrices}
              disabled={disabled}
            />
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            <Field
              name='newService.amount'
              label='Qtde'
              component={FInput}
              type='number'
              onKeyDown={onPressEnter}
              disabled={disabled}
              min={0}
              maxLength={3}
            />
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>
            <Field
              name='newService.price'
              label='Valor'
              component={FInput}
              disabled={disabled}
              type='number'
              maxLength={4}
              min={0}
              onKeyDown={onPressEnter}
            />
          </Table.HeaderCell>
          <Table.HeaderCell verticalAlign='bottom'>
            <Button
              type='button'
              icon='check'
              positive
              onClick={addServiceToTable}
              disabled={!isNewServiceValid}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {services.length ?
          (
            services.map(({ _id, name, price, amount }, index) => (
              <Table.Row key={shortid.generate()}>
                <Table.Cell content={name} />
                <Table.Cell content={`${amount} x ${displayPretty(price)}`} />
                <Table.Cell content={`R$ ${displayPretty(price * amount)}`} />
                <Table.Cell textAlign='center'>
                  <Button
                    icon='trash alternate outline'
                    color='red'
                    inverted
                    disabled={disabled}
                    onClick={() => remove(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key='NoAddedItem' textAlign='center'>
              <Table.Cell content='Nenhum item adicionado' colSpan='4' />
            </Table.Row>
          )}
      </Table.Body>
    </Table>
  )
}

export default TableServices
