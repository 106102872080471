import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { toast } from 'react-toastify'
export const _get = (object, path, defaultValue = '') => get(object, path, defaultValue)

export const _capitalize = word => capitalize(word)

/**
 *
 * @param {string} message Mensagem
 * @param {number} time Tempo, default = 5
 * @param {string} type Tipo, default = 'error'
 */
export const displayToast = (message, type = 'error') => toast[type](message, { autoClose: 3500, hideProgressBar: true })

export const getOnlyNumbersOf = string => string.replace(/\D/g, '')

/**
 * @param {string} val Valor
 */
export const displayPretty = val => parseFloat(val).toFixed(2).replace('.', ',')

export const genID = () => Math.random().toString(36).substr(2, 9)

export const getCurrentDateOrTime = (isDate = true) =>
  isDate
    ? new Date().toLocaleDateString('pt-BR')
    : new Date().toLocaleTimeString('pt-BR')

