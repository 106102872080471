import React, { PureComponent } from 'react'
import SaveOrCancel from '../../components/SaveOrCancel'
import { FInput } from '../../components/Inputs'
import { Form, withFormik, Field } from 'formik'
import { Grid } from 'semantic-ui-react'
import * as yup from 'yup'
import { servicesAPI } from '../../API'
import { displayToast } from '../../utils/Helpers'

const schema = yup.object().shape({
  name: yup.string()
    .trim()
    .required('O nome é obrigatório')
    .min(5, 'Informe pelo menos 5 letras'),
  price: yup.number()
    .positive('Informe valor positivo')
})

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({ name: '', price: '' }),
  displayName: 'serviceForm',
  isInitialValid: false,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: schema,
  handleSubmit: async (values, { props: { history } }) => {
    const result = values._id ? await servicesAPI.update(values) : await servicesAPI.insert(values)
    if (result.error) {
      displayToast(result.error)
      return
    }
    displayToast(result.message, 'success')
    history.push('/servicos')
  }
})

class ServiceForm extends PureComponent {

	componentDidMount = () => this.checkIsUpdate()

	checkIsUpdate = async () => {
	  const { id } = this.props.match.params
	  if (id) {
	    const values = await servicesAPI.get(id)
	    this.props.setValues(values)
	  }
	}

	closeForm = () => {
	  const { resetForm, history } = this.props
	  history.push('/servicos')
	  resetForm()
	}

	render() {
	  const { isSubmitting, isValid, values } = this.props
	  return (
	    <Form>
	      <Grid>
	        <Grid.Column width={9}>
	          <Field
	            name='name'
	            label='Nome'
	            component={FInput}
	            fluid
	            required
	            autoFocus
	          />
	        </Grid.Column>
	        <Grid.Column width={3}>
	          <Field
	            component={FInput}
	            name='price'
	            label='Preço'
	            type='number'
	            min={0}
	            step={0.1}
	            maxLength={4}
	          />
	        </Grid.Column>
	        <Grid.Column width={4} style={{ paddingTop: 34 }}>
	          <SaveOrCancel
	            saveText='Adicionar'
	            cancelText='Cancelar'
	            onCancel={this.closeForm}
	            disabled={isSubmitting || !isValid}
	            loading={isSubmitting}
	            isUpdate={values._id}
	          />
	        </Grid.Column>
	      </Grid>
	    </Form>
	  )
	}
}
export default formikEnhancer(ServiceForm)