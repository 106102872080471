import React from 'react'
import { Form, Grid, Segment, Button, Divider, Image, Input } from 'semantic-ui-react'
import { withFormik } from 'formik'
import * as yup from 'yup'
import logo from '../../Styles/logo.svg'
import { userAPI } from '../../API'
import { displayToast, getCurrentDateOrTime } from '../../utils/Helpers'
import styled from 'styled-components'
import { ErrorMessage } from '../../components/ErrorMessage'

const Row = styled(Grid.Row)`
	&&& {
		justify-content: center;
		align-items: center;
	}
`

const Title = styled.span`
	font-family: 'Carter One', cursive;
	font-size: 2rem;
`

const GridStyled = styled(Grid)`
	height: 100vh;
`

const Logo = styled(Image)`
	margin-bottom: 35px;
`

const StyledButton = styled(Button)`
	margin-top: 1rem;
`

const schema = yup.object().shape({
  username: yup.string()
    .trim()
    .required('Informe o usuário'),
  password: yup.string()
    .trim()
    .required('Informe a senha')
})
const { Column } = Grid

const formikEnhancer = withFormik({
  displayName: 'LoginForm',
  mapPropsToValues: () => ({ username: '', password: '' }),
  validationSchema: schema,

  handleSubmit: async (values, { props, setSubmitting }) => {
    const { isValid } = await userAPI.login(values)
    if (!isValid) {
      setSubmitting(false)
      displayToast('Usuário ou senha inválidos.')
      return
    }
    const token = generateToken(values)
    sessionStorage.token = token
    props.history.push('/vendas')
  }
})

const generateToken = ({ username }) => {

  const date = getCurrentDateOrTime()
  const time = getCurrentDateOrTime(false)
  return `${username}_${date}_${time}`
}

const Login = props => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleBlur,
    handleChange,
    handleSubmit
  } = props

  return (
    <GridStyled stackable centered>
      <Row verticalAlign='middle'>
        <Column width={7} textAlign='center' verticalAlign='middle'>
          <Logo src={logo} size='small' centered />
          <Title>yOficina - Gestão de oficinas mecânicas</Title>
        </Column>
        <Column width={5}>
          <Divider content='Bem vindo!' horizontal />
          <Segment raised>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Field
                  width={16}
                  error={errors.username && touched.username}
                >
                  <label>Usuário</label>
                  <Input
                    name='username'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    icon='user'
                    iconPosition='left'
                    fluid
                  />
                  <ErrorMessage name='username' />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field
                  width={16}
                  error={errors.password && touched.password}
                >
                  <label>Senha</label>
                  <Input
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    type='password'
                    icon='lock'
                    iconPosition='left'
                    fluid
                  />
                  <ErrorMessage name='password' />
                </Form.Field>
              </Form.Group>

              <StyledButton
                content='Entrar'
                color='green'
                icon='sign in'
                type='submit'
                disabled={isSubmitting}
                loading={isSubmitting}
                fluid
              />
            </Form>
          </Segment>
        </Column>
      </Row>
    </GridStyled>
  )
}

export default formikEnhancer(Login)
