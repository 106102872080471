import React from 'react'
import { Table } from 'semantic-ui-react'
import RemoveOrEdit from '../../components/RemoveOrEdit'
import TableMessage from '../../components/TableMessage'

const CustomerTable = ({ customers, editItem, deleteItem, tableMessage }) => {
  console.log(':: customers', customers)
  return (

    <Table striped selectable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content='Nome' />
          <Table.HeaderCell content='Celular' />
          <Table.HeaderCell content='Celular recado' />
          <Table.HeaderCell content='Telefone fixo' />
          <Table.HeaderCell content='Ações' textAlign='center' collapsing />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {!customers.length
          ? <TableMessage tableMessage={tableMessage} />
          : (
            customers.map(item => (
              <Table.Row key={item._id}>
                <Table.Cell content={item.name} />
                <Table.Cell content={item.contact.cellphone} collapsing />
                <Table.Cell content={item.contact.cellphone2} collapsing />
                <Table.Cell content={item.contact.telephone} collapsing />
                <Table.Cell collapsing>
                  <RemoveOrEdit
                    editItem={editItem}
                    item={item}
                    deleteItem={deleteItem}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )
        }
      </Table.Body>
    </Table>
  )
}

export default CustomerTable