import axios from 'axios'
const baseURL = process.env.REACT_APP_API_URL
const api = axios.create({ baseURL })
const retDefault = res => res.data


export const servicesAPI = {
  getAll: () => api.get('services').then(retDefault),
  getList: search => api.get(`services/search/${search}`).then(retDefault),
  get: id => api.get(`services/${id}`).then(retDefault),
  remove: id => api.delete(`services/${id}`).then(retDefault),
  insert: data => api.post('services', data).then(retDefault),
  update: data => api.put(`services/${data._id}`, data).then(retDefault)
}

export const customersAPI = {
  getAll: () => api.get('customers').then(retDefault),
  getList: search => api.get(`customers/search/${search}`).then(retDefault),
  get: id => api.get(`customers/${id}`).then(retDefault),
  remove: id => api.delete(`customers/${id}`).then(retDefault),
  insert: data => api.post('customers', data).then(retDefault),
  update: data => api.put(`customers/${data._id}`, data).then(retDefault)
}

export const salesAPI = {
  getAll: () => api.get('sales').then(retDefault),
  getList: search => api.get('sales/search/', { params: JSON.stringify(search) }).then(retDefault),
  getPDFData: id => api.get(`sales/pdf/${id}`).then(retDefault),
  get: id => api.get(`sales/${id}`).then(retDefault),
  remove: id => api.delete(`sales/${id}`).then(retDefault),
  insert: data => api.post('sales', data).then(retDefault),
  update: data => api.put(`sales/${data._id}`, data).then(retDefault)
}

export const userAPI = {
  login: data => api.post('user/login', data).then(retDefault)
}
export const settingsAPI = {
  getMechanics: () => api.get('settings/mechanics').then(retDefault),
  mechanic: data => api.post('settings/mechanic', data).then(retDefault)
}


export const getAddressByCep = async cep => {
  const result = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
  return result.data
}

export default api