import React, { PureComponent } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import Table from './ServiceTable'
import { servicesAPI } from '../../API'
import Loading from '../../components/Loading'
import { displayToast } from '../../utils/Helpers'
import InputSearch from '../../components/InputSearch'
import { tableMessage, noItemFound } from '../../utils/constants'

class Services extends PureComponent {
	state = {
	  services: [],
	  isLoading: false,
	  tableMessage,
	  searchText: ''
	}

	handleChange = (_, { value }) => {
	  this.setState({ searchText: value })
	}

	triggerChange = ({ keyCode, target }) => {
	  if (keyCode === 13) this.search(target.value)
	}

	search = async value => {
	  const { searchText, tableMessage } = this.state
	  value = value || searchText
	  this.setState({ isLoading: true })
	  const services = await servicesAPI.getList(searchText)
	  this.setState({
	    services,
	    tableMessage: services.length ? tableMessage : noItemFound,
	    isLoading: false
	  })
	}

	deleteItem = async _id => {
	  const { message } = await servicesAPI.remove(_id)
	  this.setState(prev => ({
	    services: prev.services.filter(x => x._id !== _id)
	  }))
	  displayToast(message, 'success')
	}

	editItem = ({ _id }) => this.redirect(`/servicos/edicao/${_id}`)

	newService = () => this.redirect('/servicos/novo')

	redirect = path => this.props.history.push(path)

	render() {
	  const { services, isLoading, searchText, tableMessage } = this.state

	  return (
			<>
				<Loading active={isLoading} />
				<Grid>
				  <Grid.Row>
				    <Grid.Column width={3}>
				      <Button
				        content='Novo Serviço'
				        onClick={this.newService}
				        icon='plus'
				        labelPosition='left'
				        color='teal'
				      />
				    </Grid.Column>
				    <Grid.Column width={7}>
				      <InputSearch
				        searchText={searchText}
				        handleChange={this.handleChange}
				        triggerChange={this.triggerChange}
				        search={this.search}
				        placeholder='Digite o nome do serviço ...'
				        color='teal'
				      />
				    </Grid.Column>
				  </Grid.Row>
				  <Grid.Row>
				    <Grid.Column>
				      <Table
				        services={services}
				        deleteItem={this.deleteItem}
				        editItem={this.editItem}
				        searchText={searchText}
				        tableMessage={tableMessage}
				      />
				    </Grid.Column>
				  </Grid.Row>
				</Grid>
			</>
	  )
	}
}

export default Services