import React, { PureComponent } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import { displayToast, getCurrentDateOrTime } from '../../utils/Helpers'
import Loading from '../../components/Loading'
import { salesAPI } from '../../API'
import Table from './SaleTable'
import InputSearch from '../../components/InputSearch'
import { tableMessage, noItemFound } from '../../utils/constants'
import pdfGenerator from '../../components/PDF'

const options = [
  { key: 'nome', text: 'Nome', value: 'name' },
  { key: 'date', text: 'Data', value: 'date' }
]

class Sale extends PureComponent {
	state = {
	  sales: [],
	  isLoading: true,
	  tableMessage,
	  searchText: '',
	  searchSelected: 'name',
	  pdfContent: ''
	}

	componentDidMount = () => {
	  this.getTodaySales()
	}

	getTodaySales = async () => {
	  const searchText = getCurrentDateOrTime()
	  const sales = await salesAPI.getList({ searchSelected: 'date', searchText })
	  const message = sales.length ? tableMessage : 'Nenhuma venda registrada hoje'
	  this.setState({
	    sales,
	    isLoading: false,
	    tableMessage: message
	  })
	}


	handleChange = (masked, isCustom) => {
	  const value = isCustom ? isCustom.value : masked.target.value
	  this.setState({ searchText: value })
	}


	triggerChange = ({ key, target, ...x }) => {
	  if (key === 'Enter') this.search(target.value)
	}


	search = async value => {
	  const { searchText, searchSelected, tableMessage } = this.state
	  value = value || searchText
	  this.setState({ isLoading: true })
	  const sales = await salesAPI.getList({ searchSelected, searchText })
	  const newMessage = sales.length ? tableMessage : noItemFound
	  this.setState({
	    sales,
	    isLoading: false,
	    tableMessage: newMessage
	  })
	}


	searchOptionSelected = searchSelected => {
	  const today = new Date().toLocaleDateString('pt-BR')
	  const searchText = searchSelected === 'date' ? today : ''

	  this.setState({ searchSelected, searchText })
	}


	deleteItem = async _id => {
	  const { message } = await salesAPI.remove(_id)
	  this.setState(prev => ({
	    sales: prev.sales.filter(x => x._id !== _id)
	  }))
	  displayToast(message, 'success')
	}


	printItem = async _id => {
	  const content = await salesAPI.getPDFData(_id)
	  pdfGenerator(content)
	}

	newSale = () => this.redirect('/nova')

	editItem = sale => this.redirect(`/edicao/${sale._id}`)

	redirect = path => this.props.history.push(`/vendas${path}`)

	render() {
	  const { sales, isLoading, searchSelected, searchText, tableMessage } = this.state
	  return (
			<>
				<Loading active={isLoading} />
				<Grid>
				  <Grid.Row>
				    <Grid.Column width={3}>
				      <Button
				        content='Nova venda'
				        onClick={this.newSale}
				        icon='plus'
				        labelPosition='left'
				        color='green'
				      />
				    </Grid.Column>
				    <Grid.Column width={7}>
				      <InputSearch
				        searchText={searchText}
				        searchSelected={searchSelected}
				        handleChange={this.handleChange}
				        triggerChange={this.triggerChange}
				        search={this.search}
				        optionSelected={this.searchOptionSelected}
				        options={options}
				        placeholder='Digite o nome do cliente ...'
				        color='green'
				      />
				    </Grid.Column>
				  </Grid.Row>
				  <Grid.Row>
				    <Grid.Column>
				      <Table
				        sales={sales}
				        deleteItem={this.deleteItem}
				        editItem={this.editItem}
				        printItem={this.printItem}
				        tableMessage={tableMessage}
				      />
				    </Grid.Column>
				  </Grid.Row>
				</Grid>
			</>
	  )
	}
}


export default Sale
