import React, { Component } from 'react'
import { Menu, Segment, Icon } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'

class Main extends Component {
  state = { activeItem: 'Início' }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
  }

  leave = () => {
    sessionStorage.removeItem('token')
    this.props.history.push('/login')
  }

  render() {
    const { activeItem } = this.state
    const { children } = this.props
    return (
      <>
        <Menu fixed='left' pointing vertical className={css(styles.menu)}>
          <MenuWrapper>
            <MenuLink
              to='/'
              name='Início'
              activeItem={activeItem}
              icon='map marker alternate'
              onClick={this.handleItemClick}
            />
            <MenuLink
              to='/clientes'
              name='Clientes'
              activeItem={activeItem}
              icon='user'
              onClick={this.handleItemClick}
            />
            <MenuLink
              to='/servicos'
              name='Serviços'
              activeItem={activeItem}
              icon='wrench'
              onClick={this.handleItemClick}
            />
            <MenuLink
              to='/vendas'
              name='Vendas'
              activeItem={activeItem}
              icon='dollar'
              onClick={this.handleItemClick}
            />
          </MenuWrapper>
          <div>
            <MenuLink
              to='/configuracoes'
              name='Configurações'
              icon='settings'
              onClick={this.handleItemClick}
            />
            <Menu.Item name='Sair' onClick={this.leave}>
              <Icon name='sign out' />
              Sair
            </Menu.Item>
          </div>
        </Menu>
        <Segment className={css(styles.segment)}>
          {children}
        </Segment>
      </>
    )
  }
}

const MenuLink = ({ name, to, icon, activeItem, ...rest }) => (
  <Menu.Item
    as={Link}
    to={to}
    name={name}
    active={activeItem === name}
    {...rest}
  >
    <Icon name={icon} />
    {name}
  </Menu.Item>
)

const MenuWrapper = ({ children }) => (
  <div className={css(styles.menuChild)}>
    {children}
  </div>
)

const styles = StyleSheet.create({
  menu: {
    width: '11rem',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuChild: {
    height: '100%'
  },
  segment: {
    margin: '0 0 0 11rem',
    minHeight: '100vh'
  }
})

export default withRouter(Main)