import React, { memo } from 'react'
import { Table } from 'semantic-ui-react'
import RemoveOrEdit from '../../components/RemoveOrEdit'
import { _capitalize } from '../../utils/Helpers'
import TableMessage from '../../components/TableMessage'

const SaleTable = ({ sales, editItem, deleteItem, tableMessage, printItem }) => (
  <Table striped selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content='Nome' />
        <Table.HeaderCell content='Contato' />
        <Table.HeaderCell content='Veículo' />
        <Table.HeaderCell content='Situação' />
        <Table.HeaderCell content='Última Atualização' />
        <Table.HeaderCell content='Data da Finalização' />
        <Table.HeaderCell content='Ações' textAlign='center' collapsing />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {!sales.length
        ? <TableMessage tableMessage={tableMessage} />
        : (
          sales.map(item => (
            <Table.Row key={item._id}>
              {console.log('​item', item)}
              <Table.Cell content={item.customer.name} />
              <Table.Cell content={getContact(item.customer)} />
              <Table.Cell content={item.customer.cars[item.carIndex].model} />
              <Table.Cell content={_capitalize(item.situation)} collapsing />
              <Table.Cell content={item.modifiedAt} collapsing />
              <Table.Cell content={item.finishedAt} collapsing />
              <Table.Cell collapsing>
                <RemoveOrEdit
                  editItem={editItem}
                  item={item}
                  deleteItem={deleteItem}
                  printItem={printItem}
                  usePrint
                />
              </Table.Cell>
            </Table.Row>
          ))
        )
      }
    </Table.Body>
  </Table>
)

const getContact = x => x.cellphone || x.cellphone2 || x.telephone

export default memo(SaleTable)
