import React from 'react'
import { render } from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import './Styles/styles.css'
import App from './App'

render(<App />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}
